<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i> Добавить часто задаваемый вопрос
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="faq"
                    ref="grid"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    :detail="detailTemplate"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:order="{ props: { dataItem } }">
                        <td>
                            <div>
                                <a
                                    @click.prevent="
                                        moveQuestion(dataItem.id, 'up')
                                    "
                                    class="dropdown-item"
                                    title="Переместить наверх"
                                >
                                    <i class="fa fa-arrow-up"></i>
                                </a>
                                <a
                                    @click.prevent="
                                        moveQuestion(dataItem.id, 'down')
                                    "
                                    class="dropdown-item"
                                    title="Переместить вниз"
                                >
                                    <i class="fa fa-arrow-down"></i>
                                </a>
                            </div>
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper">
                            <table class="table table-striped m-table">
                                <tbody>
                                    <tr>
                                        <td width="150px">
                                            <div class="font-weight-bold">
                                                Вопрос
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>{{ dataItem.question }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="font-weight-bold">
                                                Ответ
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                v-html="dataItem.answer"
                                                class="reset-all-styles"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </Grid>
            </intl>
        </localization>
        <faq-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            @input="$log('input fgfgd')"
            :show-dialog="showEntityModal"
            :product-type="productType"
            @click:outside="
                $log = 'Close outside'
                showEntityModal = false
            "
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></faq-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import { Dealer } from '@/config/product_types'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import FaqModal from '../admin/modals/FaqCreateUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { base_url } from '@/lib/axios'
    import { showMessage } from '@/lib/toasted'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'faq',
        components: {
            Grid: Grid,
            ActionsDropdown,
            localization: LocalizationProvider,
            intl: IntlProvider,
            breadcrumbs: Breadcrumbs,
            'faq-modal': FaqModal,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            await this.getData()
        },
        data() {
            return {
                base_url,
                productType: Dealer,
                detailTemplate: 'detail',
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'question',
                        title: 'Вопрос',
                    },
                    {
                        field: 'order',
                        title: 'Порядок',
                        cell: 'order',
                        filterable: false,
                        sortable: false,
                    },
                ],
            }
        },
        methods: {
            fetchData: api.faq.getFaq,
            async moveQuestion(id, direction) {
                try {
                    const {
                        data: { status, message },
                    } = await api.faq.moveQuestion(id, direction)
                    showMessage(status, message)

                    this.updateTable()
                } catch (e) {}
            },
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный часто задаваемый вопрос?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.faq.deleteQuestion(item.id)
                    showMessage(status, message)

                    this.updateTable()
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
        },
    }
</script>

<style scoped></style>
