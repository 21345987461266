var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var valid = ref.valid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"1014"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"fa fa-question-circle mr-2"}),_vm._v("Редактирование статической страницы")])]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-4",attrs:{"column":""}},[_c('ValidationProvider',{attrs:{"name":"question","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-text-field',{attrs:{"label":"Вопрос *","hide-details":"auto","required":"","error":invalid && validated},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})]}}],null,true)})],1),_c('div',{staticClass:"text-h6 font-weight-medium black--text"},[_vm._v(" Ответ ")]),_c('ckeditor',{attrs:{"editor-url":"/js/ckeditor/ckeditor.js","config":_vm.editorConfig},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1)],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid || !_vm.answer,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-save mr-2"}),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }