var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"8"}},[_c('CButton',{staticClass:"add-product-modal",attrs:{"color":"info"},on:{"click":function($event){_vm.clearCurrentItem()
                    _vm.showEntityModal = true}}},[_c('i',{staticClass:"la la-plus"}),_vm._v(" Добавить часто задаваемый вопрос ")])],1)],1),_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"faq",attrs:{"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded',"detail":_vm.detailTemplate},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('actions-dropdown',{attrs:{"actions":[
                                    {
                                        text: 'Редактировать',
                                        icon: 'la la-pencil',
                                        handler: function () {
                                            _vm.setCurrentItem(dataItem)
                                            _vm.showEntityModal = true
                                        },
                                    },
                                    {
                                        text: 'Удалить',
                                        icon: 'la la-trash',
                                        handler: function () { return _vm.deleteEntity(dataItem); },
                                    } ]}})],1)])]}},{key:"order",fn:function(ref){
                                var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',[_c('a',{staticClass:"dropdown-item",attrs:{"title":"Переместить наверх"},on:{"click":function($event){$event.preventDefault();return _vm.moveQuestion(dataItem.id, 'up')}}},[_c('i',{staticClass:"fa fa-arrow-up"})]),_c('a',{staticClass:"dropdown-item",attrs:{"title":"Переместить вниз"},on:{"click":function($event){$event.preventDefault();return _vm.moveQuestion(dataItem.id, 'down')}}},[_c('i',{staticClass:"fa fa-arrow-down"})])])])]}},{key:"detail",fn:function(ref){
                                var dataItem = ref.props.dataItem;
return [_c('div',{staticClass:"detail-wrapper"},[_c('table',{staticClass:"table table-striped m-table"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"width":"150px"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Вопрос ")])])]),_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(dataItem.question))])])]),_c('tr',[_c('td',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Ответ ")])])]),_c('tr',[_c('td',[_c('div',{staticClass:"reset-all-styles",domProps:{"innerHTML":_vm._s(dataItem.answer)}})])])])])])]}}])})],1)],1),(_vm.showEntityModal)?_c('faq-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal,"product-type":_vm.productType},on:{"input":function($event){return _vm.$log('input fgfgd')},"click:outside":function($event){_vm.$log = 'Close outside'
            _vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }