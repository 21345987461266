<template>
  <ValidationObserver slim v-slot="{ invalid, valid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="1014"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-question-circle mr-2"></i>Редактирование статической страницы</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <v-layout column>
                <v-layout class="mb-4" column>
                  <ValidationProvider name="question" rules="required" v-slot="{invalid, validated}">
                    <v-text-field
                        label="Вопрос *"
                        v-model="question"
                        hide-details="auto"
                        required
                        :error="invalid && validated"
                    ></v-text-field>
                  </ValidationProvider>
                </v-layout>
                <div class="text-h6 font-weight-medium black--text">
                  Ответ
                </div>
                <ckeditor editor-url="/js/ckeditor/ckeditor.js" v-model="answer" :config="editorConfig"></ckeditor>
              </v-layout>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid || !answer" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "FaqModal",
  components: {
    'ckeditor': CKEditor.component,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [EntityModalMixin],
  data() {
    let state = {
      id: null,
      question: '',
      answer: '',
      editorConfig: {
        height: '34vh'
      }
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.faq.createQuestion,
    updateEntity: api.faq.updateQuestion,
    prepareUpdateEntityParams() {
      return [this.id, this.data];
    },
  },
  computed: {
    data() {
      return {
        id: this.id,
        question: this.question,
        answer: this.answer
      }
    }
  }
}
</script>
